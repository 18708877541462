// Landing Page Data
export const landingPageData = {
  coming_soon: "Coming Soon!",
  heading: "joystack.",
  notify: {
    text: "joystack BETA is concluded. Get notified when we launch again.",
    button: "Notify Me",
    placeholder: "Enter Your Email Here...",
  },
};

// Social Media List
export const socialMediaData = [
  {
    icon: "fab fa-twitter",
    link: "http://www.twitter.com/joystackcom",
    name: "Twitter",
    class: "social-icons-twitter",
  },
  {
    icon: "fab fa-discord",
    link: "http://discord.joystack.com/",
    name: "Discord",
    class: "social-icons-discord",
  },
];

// About Us Data
export const aboutData = {
  desc: "We are on a mission to make the cloud work for you by making complicated and expensive things free and simple. ",
  cards: [
    {
      icon: "fas fa-code",
      title: "NoCode",
      desc: "Build your custom project with visual builders instead of learning to code.",
    },
    {
      icon: "fas fa-cloud",
      title: "Serverless",
      desc: "Let your application dynamically run instead of managing servers.",
    },
    {
      icon: "fas fa-rocket",
      title: "Scale",
      desc: "Scale with confidence knowing that capacity for you to grow is available 24/7.",
    },
  ],
  projects_title: "Some of our most recent projects.",
  projects: [
    {
      title: "Minecraft Hosting",
      category: "Category",
      img: "images/intro-bg-2.jpg",
    },

  ],
  get_in_touch: "Get in touch with us",
};

// Contact Us data
export const contactData = {
  desc: "Until the next version of Joystack is launched you can get in touch via email or Discord.",
  support_note: "For Customer Support, Send us a note.",
  cards: [
    {
      icon: "fas fa-envelope",
      title: "eMail",
      lines: ["info@joystack.com"],
    },
    {
      icon: "fab fa-discord",
      title: "Discord",
      lines: ["https://discord.joystack.com"],
    },
  ],

  form: {
    nameLable: "What is Your Name:",
    namePlaceholder: "Enter Your Name",

    emailLable: "Your Email Address:",
    emailPlaceholder: "Enter Your Email",

    messageLable: "How can I Help you?",
    messagePlaceholder: "Enter Your Query",

    submitButton: "Send Message",
  },
  contact: "(+91) 11-2142-566",
};
